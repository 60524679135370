/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DataAppType {
    stranded = 'stranded',
    toilet = 'toilet',
    bed = 'bed',
    notset = 'notset',
}
