/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DataAppStranded = {
    type: DataAppStranded.type;
    payload: {
        /**
         * Timestamp of the event in milliseconds since epoch
         */
        timestamp: number;
        /**
         * The event duration
         */
        duration: number;
    };
};

export namespace DataAppStranded {

    export enum type {
        Stranded = 0,
    }


}

