<div class="wrapper">
  <div class="toolbar">
    <span class="title">{{ data.title }}</span>
    <span class="close-btn" (click)="onCanceled()">X</span>
  </div>
  <div class="content">
    <div
      *ngIf="data.fieldTitle"
      class="subtitle"
      style="display: flex"
      [innerHTML]="data.fieldTitle"
    ></div>
    <container [ngSwitch]="data.typeDialog">
      <div *ngSwitchCase="'select'">
        <mat-select
          class="room-select"
          placeholder="Choose.."
          i18n-placeholder="@@choose-placeholder"
          [(ngModel)]="selectedValue"
        >
          <mat-option *ngFor="let option of data.list" [value]="option">
            {{ option[data.displayNameForList] }}
          </mat-option>
        </mat-select>
      </div>
      <div *ngSwitchCase="'input'">
        <mat-form-field appearance="outline" class="value-field">
          <input
            matInput
            name="suiteName"
            placeholder="Suite name"
            i18n-placeholder="@@suite-name"
            [formControl]="nameFormControl"
          />
        </mat-form-field>
      </div>
      <div *ngSwitchCase="'message'"  
        class="message"
        [innerHTML]="data.message | linebreak">
      </div>
    </container>

    <div style="width: 100%; margin-top: 44px">
      <div style="display: inline-flex; float: right; margin-bottom: 1em">
        <button mat-button class="cancel-btn" (click)="onCanceled()">
          {{ data.cancelBtnText }}
        </button>
        <button
          mat-button
          class="btn"
          [disabled]="checkDisabled()"
          (click)="onConfirmed()"
          data-cy="dialog-confirm-button"
        >
          <div class="spinner-wrapper">
            <mat-spinner diameter="15" *ngIf="inProgress"> </mat-spinner>
          </div>
          <span> {{ data.okBtnText }}</span>
        </button>
      </div>
    </div>
  </div>
</div>