/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DataAppToilet = {
    type: DataAppToilet.type;
    payload: {
        /**
         * Timestamp of the event in milliseconds since epoch
         */
        timestamp: number;
        /**
         * The event duration
         */
        duration: number;
    };
};

export namespace DataAppToilet {

    export enum type {
        Toilet = 1,
    }


}

