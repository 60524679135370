import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as L from "leaflet";
import "leaflet-draw";
import {
  DrawMap,
  LatLngBoundsExpression,
  LatLngBoundsLiteral,
  LatLngExpression,
  LatLngTuple,
} from "leaflet";
import { RoomSizeForm, RoomSizeFromForm } from "../device-settings-interface";
import { SensorMounting, TrackerSubRegion } from "@walabot-mqtt-dashboard/api";
import { convertMetersToFeet } from "../device-settings-utils";
import { FormArray, FormGroup } from "@angular/forms";
import { ArrowheadOptions } from "leaflet-arrowheads";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "../../../ui.module";
import "leaflet-path-transform";
import { TrackerTarget2D } from "../../../models";
require("leaflet-arrowheads");
require("../../../../assets/js/leaflet.latlng-graticule");

// Workaround for https://github.com/Leaflet/Leaflet.draw/issues/1026
// eslint-disable-next-line
// @ts-ignore
if (!("type" in window)) {
  // eslint-disable-next-line
  // @ts-ignore
  window.type = "";
}

// Fix for leaflet-path-drag@1.8.0-beta.3
/* eslint-disable */
L.Handler["PathDrag"].prototype._onDrag = function (evt) {
  L.DomEvent.stop(evt);
  const first = evt.touches && evt.touches.length >= 1 ? evt.touches[0] : evt;
  const containerPoint = this._path._map.mouseEventToContainerPoint(first);

  // skip taps
  if (evt.type === "touchmove" && !this._path._dragMoved) {
    const totalMouseDragDistance =
      this._dragStartPoint.distanceTo(containerPoint);
    if (totalMouseDragDistance <= this._path._map.options.tapTolerance) {
      return;
    }
  }

  const x = containerPoint.x;
  const y = containerPoint.y;

  const dx = x - this._startPoint.x;
  const dy = y - this._startPoint.y;

  // Send events only if point was moved
  if (dx || dy) {
    if (!this._path._dragMoved) {
      this._path._dragMoved = true;
      this._path.options.interactive = false;
      // this._path._map.dragging._draggable._moved = true;

      this._path.fire("dragstart", evt);
      // we don't want that to happen on click
      this._path.bringToFront();
    }

    this._matrix[4] += dx;
    this._matrix[5] += dy;

    this._startPoint.x = x;
    this._startPoint.y = y;

    this._path.fire("predrag", evt);
    this._path._transform(this._matrix);
    this._path.fire("drag", evt);
  }
};
/* eslint-enable */

interface Transform {
  disable: () => void;
  enable: (options?: {
    rotation?: boolean;
    scaling?: boolean;
    uniformScaling?: boolean;
  }) => void;
  _matrix: {
    transform: (point: L.Point) => L.Point;
  };
  reset: () => void;
}

declare module "leaflet" {
  class LatLngGraticule extends L.Layer {}

  interface ZoomIntervalEntry {
    start: number;
    end: number;
    interval: number;
  }
  interface LatLngGraticuleOptions {
    showLabel?: boolean;
    opacity?: number;
    weight?: number;
    color?: string;
    fontColor?: string;
    font?: string;
    dashArray?: number[];
    sides?: string[];
    zoomInterval?:
      | {
          latitude: [ZoomIntervalEntry, ZoomIntervalEntry];
          longitude: [ZoomIntervalEntry, ZoomIntervalEntry];
        }
      | Array<ZoomIntervalEntry>;
    latFormatTickLabel?(lat: number): string;
    lngFormatTickLabel?(lng: number): string;
  }
  interface Rectangle {
    transform: Transform;
  }
  interface PolylineOptions {
    transform?: boolean;
  }

  function latlngGraticule(options: LatLngGraticuleOptions): LatLngGraticule;
}

@Component({
  selector: "app-preview2d",
  templateUrl: "./preview2d.component.html",
  styleUrls: ["./preview2d.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Preview2dComponent
  extends BaseComponent
  implements OnChanges, AfterViewInit
{
  @Input() config: Record<string, any>;
  @Input() getSettings: () => {
    deviceSettingsForm: {
      sensorMounting: SensorMounting;
    };
  };
  @Input() getRoomSizeFromForm: () => RoomSizeFromForm;
  @Input() getSubregionsFromForm: () => Array<TrackerSubRegion>;
  @Input() isMetric: () => boolean;
  @Input() roomSize: FormGroup<RoomSizeForm>;
  @Input() subRegions: FormArray<FormGroup>;
  @Input() drawSubRegion: EventEmitter<unknown>;
  @Input() selectedSubRegionIndex: number;
  @Input() isSubRegionsEditable = false;
  @Input() targets: Array<TrackerTarget2D> = [];
  @Output() addSubRegion = new EventEmitter<TrackerSubRegion>();
  @Output() newSelectedSubRegionIndex = new EventEmitter<number>();

  @ViewChild("map") mapElement: ElementRef<HTMLDivElement>;
  insideUnitTest = false;
  private map: L.Map;
  private roomLayer: L.Rectangle;
  private deviceLayer: L.Circle;
  private grid: L.LatLngGraticule;
  private subRegionsLayer = new L.LayerGroup();
  private legendsLayer = new L.LayerGroup();
  private arrowsLayers = new L.LayerGroup();
  private targetsLayers = new L.LayerGroup();
  private currentNewSubRegionControl: L.Draw.Rectangle;
  private inEditMode = false;

  constructor(private ngZone: NgZone) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.map &&
      ("config" in changes || "selectedSubRegionIndex" in changes) &&
      !this.inEditMode
    ) {
      this.updateCanvas();
    }
    if (this.map && "targets" in changes) {
      this.updateTargets();
    }
  }

  ngAfterViewInit() {
    if (!this.insideUnitTest) {
      this.ngZone.runOutsideAngular(() => {
        this.map = L.map(this.mapElement.nativeElement, {
          attributionControl: false,
          zoom: 1,
          zoomSnap: 0.1,
          center: [0, 0],
          minZoom: -1,
          maxZoom: 22,
          crs: L.CRS.Simple,
          zoomControl: false,
          scrollWheelZoom: false,
          dragging: false,
        });

        this.map.on(L.Draw.Event.CREATED, (e) => {
          const bounds = (e.layer as L.Rectangle).getBounds();
          this.addSubRegion.emit(
            Object.assign(
              {
                zMin: null,
                zMax: null,
                mode: null,
                enterDuration: null,
                exitDuration: null,
                isFallingDetection: null,
                isPresenceDetection: null,
                isLowSnr: null,
                isHorizontal: null,
                isDoor: null,
                name: null,
              },
              this.getMeasurements(bounds)
            )
          );
        });

        const isMetric = this.isMetric();
        this.grid = L.latlngGraticule({
          showLabel: true,
          dashArray: [0.5, 0.5],
          sides: ["-", "", "-", ""],
          zoomInterval: [{ start: 2, end: 20, interval: 0.5 }],
          latFormatTickLabel(this: L.LatLngGraticuleOptions, _lat: number) {
            let lat: number | string,
              sides = this.sides[0];
            if (_lat < 0) {
              sides = this.sides[1];
              _lat *= -1;
            }
            if (isMetric) {
              lat = _lat;
            } else {
              const [feet, inches] = convertMetersToFeet(_lat);
              lat = `${feet}'${inches}"`;
            }
            return "" + sides + <string>lat;
          },
          lngFormatTickLabel(this: L.LatLngGraticuleOptions, _lng: number) {
            let lng: number | string,
              sides = this.sides[2];
            if (_lng < 0) {
              sides = this.sides[3];
              _lng *= -1;
            }
            if (isMetric) {
              lng = _lng;
            } else {
              const [feet, inches] = convertMetersToFeet(_lng);
              lng = `${feet}'${inches}"`;
            }
            return "" + sides + <string>lng;
          },
        });
        this.grid.addTo(this.map);

        this.map.addLayer(this.subRegionsLayer);
        this.map.addLayer(this.legendsLayer);
        this.map.addLayer(this.arrowsLayers);
        this.map.addLayer(this.targetsLayers);

        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        });
      });
    }
    this.drawSubRegion &&
      this.drawSubRegion.pipe(takeUntil(this.ngUnsubsrcibe)).subscribe(() => {
        this.startNewSubRegion();
      });
  }

  @HostListener("window:resize", ["$event"])
  public onResize() {
    if (this.map && this.getSettings()) {
      this.updateCanvas();
    }
  }

  private updateCanvas() {
    this.ngZone.runOutsideAngular(() => {
      this.updateGrid();
      this.updateDevice();
      this.updateRoom();
      this.updateSubRegions();
      this.map.dragging.disable();
    });
  }

  private updateTargets() {
    this.ngZone.runOutsideAngular(() => {
      this.targetsLayers.clearLayers();
      this.targets.forEach((target) => {
        this.targetsLayers.addLayer(
          L.marker(this.convertCoordinates([[target.y, target.x]])[0], {
            icon: L.icon({ iconUrl: target.image, iconSize: [20, 50] }),
          })
        );
      });
    });
  }

  private updateRoom() {
    const roomSize = this.getRoomSizeFromForm(),
      bounds: LatLngBoundsExpression = [
        [roomSize.yMin, roomSize.xMin],
        [roomSize.yMax, roomSize.xMax],
      ];
    if (!bounds.flat().every((v) => typeof v === "number")) {
      return;
    }
    if (this.roomLayer) {
      this.roomLayer.transform?.disable();
      this.map.removeLayer(this.roomLayer);
    }
    this.legendsLayer.clearLayers();
    const roomOptions: L.PolylineOptions = {
      interactive: false,
      transform: !this.isSubRegionsEditable,
    };
    if (this.roomSize?.invalid) {
      Object.assign(roomOptions, {
        color: "red",
        fillColor: "red",
      });
    }
    this.roomLayer = new L.Rectangle(
      this.convertCoordinates(bounds),
      roomOptions
    );
    this.map.addLayer(this.roomLayer);
    if (!this.isSubRegionsEditable) {
      this.roomLayer.transform.enable({
        rotation: false,
        scaling: true,
        uniformScaling: false,
      });
      this.roomLayer
        .on("scalestart", () => {
          this.inEditMode = true;
        })
        .on("scale", () => {
          const matrix = this.roomLayer.transform._matrix;
          const bounds = L.latLngBounds(
            this.roomLayer.getLatLngs() as LatLngExpression[]
          );
          const transform = (coord: L.LatLng) => {
            return this.map.layerPointToLatLng(
              matrix.transform(this.map.latLngToLayerPoint(coord))
            );
          };
          const {
            xMin: roomXMin,
            xMax: roomXMax,
            yMin: roomYMin,
            yMax: roomYMax,
          } = this.getMeasurements(bounds, transform);
          const [roomXMinFeet, roomXMinInches] = convertMetersToFeet(roomXMin);
          const [roomXMaxFeet, roomXMaxInches] = convertMetersToFeet(roomXMax);
          const [roomYMinFeet, roomYMinInches] = convertMetersToFeet(roomYMin);
          const [roomYMaxFeet, roomYMaxInches] = convertMetersToFeet(roomYMax);
          this.roomSize.patchValue({
            roomXMin,
            roomXMax,
            roomYMin,
            roomYMax,
            roomXMinFeet,
            roomXMinInches,
            roomXMaxFeet,
            roomXMaxInches,
            roomYMinFeet,
            roomYMinInches,
            roomYMaxFeet,
            roomYMaxInches,
          });
          this.roomSize.markAsDirty();
        })
        .on("scaleend", () => {
          this.inEditMode = false;
          this.updateRoom();
          this.updateSubRegions();
        });
    }
    this.map.fitBounds([...this.convertCoordinates(bounds), [0, 0]], {
      padding: [70, 70],
    });
    setTimeout(() => {
      if (this.isWallMount()) {
        L.tooltip({
          permanent: true,
          direction: "left",
          className: "legend room-size",
        })
          .setContent(`xMax`)
          .setLatLng([0.1, -Math.abs(roomSize.xMax) / 2])
          .addTo(this.legendsLayer);

        L.tooltip({
          permanent: true,
          direction: "right",
          className: "legend room-size",
        })
          .setContent(`xMin`)
          .setLatLng([0.1, Math.abs(roomSize.xMin) / 2])
          .addTo(this.legendsLayer);
      } else {
        // Ceiling
        L.tooltip({
          permanent: true,
          direction: "right",
          className: "legend room-size",
        })
          .setContent(`xMax`)
          .setLatLng([0, roomSize.xMax])
          .addTo(this.legendsLayer);
        L.tooltip({
          permanent: true,
          direction: "left",
          className: "legend room-size",
        })
          .setContent(`xMin`)
          .setLatLng([0, roomSize.xMin])
          .addTo(this.legendsLayer);
        L.tooltip({
          permanent: true,
          direction: "bottom",
          className: "legend room-size",
        })
          .setContent(`yMin`)
          .setLatLng([roomSize.yMin, 0])
          .addTo(this.legendsLayer);
        L.tooltip({
          permanent: true,
          direction: "top",
          className: "legend room-size",
        })
          .setContent(`yMax`)
          .setLatLng([roomSize.yMax, 0])
          .addTo(this.legendsLayer);
      }
    });
  }

  private updateSubRegions() {
    this.subRegionsLayer.eachLayer((layer: L.Rectangle) => {
      layer.transform?.disable();
    });
    this.subRegionsLayer.clearLayers();
    const subRegions = this.getSubregionsFromForm();
    subRegions.forEach((subRegion, index) => {
      const bounds: LatLngBoundsExpression = [
        [subRegion.yMin, subRegion.xMin],
        [subRegion.yMax, subRegion.xMax],
      ];
      if (!bounds.flat().every((v) => typeof v === "number")) {
        return;
      }
      const activeColor = "yellow",
        defaultColor = "#87E11D";
      let color =
        this.selectedSubRegionIndex === index ? activeColor : defaultColor;
      if (this.subRegions.at(index).invalid) {
        color = "red";
      }
      const subRegionOptions = {
        color,
        fillColor: color,
        interactive: this.isSubRegionsEditable,
        draggable: this.isSubRegionsEditable,
        transform: this.isSubRegionsEditable,
        className: this.isSubRegionsEditable ? "draggable" : "",
      };
      const rectangle = new L.Rectangle(
        this.convertCoordinates(bounds),
        subRegionOptions
      );
      const tooltip = new L.Tooltip({ permanent: true, direction: "center" });
      tooltip.setContent(subRegion.name);
      rectangle.bindTooltip(tooltip).openTooltip();
      this.subRegionsLayer.addLayer(rectangle);
      if (this.isSubRegionsEditable) {
        rectangle.transform.enable({
          rotation: false,
          scaling: true,
          uniformScaling: false,
        });
      }
      const transformStart = () => {
          this.inEditMode = true;
          this.newSelectedSubRegionIndex.emit(index);
          rectangle.setStyle({
            color: activeColor,
            fillColor: activeColor,
          });
          this.subRegionsLayer.eachLayer((layer: L.Rectangle) => {
            if (layer !== rectangle) {
              layer.setStyle({
                color: defaultColor,
                fillColor: defaultColor,
              });
            }
          });
          tooltip.close();
        },
        transform = () => {
          const matrix = rectangle.transform._matrix;
          const bounds = L.latLngBounds(
            rectangle.getLatLngs() as LatLngExpression[]
          );
          const transform = (coord: L.LatLng) => {
            return this.map.layerPointToLatLng(
              matrix.transform(this.map.latLngToLayerPoint(coord))
            );
          };
          const { xMin, xMax, yMin, yMax } = this.getMeasurements(
            bounds,
            transform
          );
          const [xMinFeet, xMinInches] = convertMetersToFeet(xMin);
          const [xMaxFeet, xMaxInches] = convertMetersToFeet(xMax);
          const [yMinFeet, yMinInches] = convertMetersToFeet(yMin);
          const [yMaxFeet, yMaxInches] = convertMetersToFeet(yMax);
          this.subRegions.at(index).patchValue({
            xMin,
            xMax,
            yMin,
            yMax,
            xMinFeet,
            xMinInches,
            xMaxFeet,
            xMaxInches,
            yMinFeet,
            yMinInches,
            yMaxFeet,
            yMaxInches,
          });
          this.subRegions.markAsDirty();
        },
        transformEnd = () => {
          this.inEditMode = false;
          this.updateSubRegions();
        };
      rectangle
        .on("dragstart", transformStart)
        .on("drag", transform)
        .on("dragend", transformEnd)
        .on("scalestart", transformStart)
        .on("scale", transform)
        .on("scaleend", transformEnd);
    });
  }

  private updateGrid() {
    const arrowColor = "#91b4f5";
    const arrowOptions: ArrowheadOptions = {
        size: "10px",
        fill: true,
        color: arrowColor,
        interactive: false,
      },
      arrowPolyLineOptions = {
        color: arrowColor,
        interactive: false,
      };
    let gridOptionsSides: Array<string>;
    this.arrowsLayers.clearLayers();
    switch (this.getSettings().deviceSettingsForm.sensorMounting) {
      case SensorMounting.Wall:
      case SensorMounting.Tilt45Deg:
        gridOptionsSides = ["-", "", "-", ""];
        L.polyline(
          [
            [0, 0],
            [-1, 0],
          ],
          arrowPolyLineOptions
        )
          .arrowheads(arrowOptions)
          .addTo(this.arrowsLayers);
        L.polyline(
          [
            [0, 0],
            [0, -1],
          ],
          arrowPolyLineOptions
        )
          .arrowheads(arrowOptions)
          .addTo(this.arrowsLayers);
        break;
      case SensorMounting.Ceiling:
        gridOptionsSides = ["", "-", "", "-"];
        L.polyline(
          [
            [0, 0],
            [0, 1],
          ],
          arrowPolyLineOptions
        )
          .arrowheads(arrowOptions)
          .addTo(this.arrowsLayers);
        L.polyline(
          [
            [0, 0],
            [1, 0],
          ],
          arrowPolyLineOptions
        )
          .arrowheads(arrowOptions)
          .addTo(this.arrowsLayers);
        // Cable
        L.polyline([
          [0, 0],
          [0, -0.5],
        ]).addTo(this.arrowsLayers);
        break;
      case SensorMounting.Ceiling45Deg:
        gridOptionsSides = ["", "-", "", "-"];
        L.polyline(
          [
            [0, 0],
            [0, 1],
          ],
          arrowPolyLineOptions
        )
          .arrowheads(arrowOptions)
          .addTo(this.arrowsLayers);
        L.polyline(
          [
            [0, 0],
            [1, 0],
          ],
          arrowPolyLineOptions
        )
          .arrowheads(arrowOptions)
          .addTo(this.arrowsLayers);
        // Cable
        L.polyline([
          [0, 0],
          [0.5, -0.5],
        ]).addTo(this.arrowsLayers);
        break;
    }

    this.grid["options"]["sides"] = gridOptionsSides;
  }

  private updateDevice() {
    this.deviceLayer && this.map.removeLayer(this.deviceLayer);

    this.deviceLayer = L.circle([0, 0], 0.1, {
      fillOpacity: 1,
      interactive: false,
    })
      .bindTooltip("Device", {
        permanent: true,
        direction: "top",
        offset: [0, -20],
        className: "legend",
      })
      .openTooltip()
      .addTo(this.map);
  }

  private convertCoordinates(bounds: LatLngBoundsLiteral): LatLngBoundsLiteral {
    if (this.isWallMount()) {
      return bounds.map((tuple) => <LatLngTuple>tuple.map((v) => v * -1));
    } else {
      return bounds;
    }
  }

  private startNewSubRegion() {
    this.exitNewSubRegionMode();
    this.currentNewSubRegionControl = new L.Draw.Rectangle(
      <DrawMap>this.map,
      <L.DrawOptions.RectangleOptions>{
        showArea: false,
      }
    );
    this.currentNewSubRegionControl.enable();
  }

  private exitNewSubRegionMode() {
    if (this.currentNewSubRegionControl) {
      this.currentNewSubRegionControl.disable();
    }
  }

  private getMeasurements(
    bounds: L.LatLngBounds,
    transform = (a: L.LatLng) => a
  ) {
    const fractionDigits = 1;
    let { lng: xMin, lat: yMin } = transform(bounds.getSouthWest()),
      { lng: xMax, lat: yMax } = transform(bounds.getNorthEast());
    if (this.isWallMount()) {
      const flip = -1,
        _xMax = xMax,
        _yMax = yMax;
      xMax = xMin * flip;
      xMin = _xMax * flip;
      yMax = yMin * flip;
      yMin = _yMax * flip;
    }

    const roomSize = this.getRoomSizeFromForm();
    const rounded = {
      xMin: +xMin.toFixed(fractionDigits),
      xMax: +xMax.toFixed(fractionDigits),
      yMin: +yMin.toFixed(fractionDigits),
      yMax: +yMax.toFixed(fractionDigits),
    };

    if (xMin >= roomSize.xMin && rounded.xMin < roomSize.xMin) {
      rounded.xMin = roomSize.xMin;
    }
    if (yMin >= roomSize.yMin && rounded.yMin < roomSize.yMin) {
      rounded.yMin = roomSize.yMin;
    }
    if (xMax <= roomSize.xMax && rounded.xMax > roomSize.xMax) {
      rounded.xMax = roomSize.xMax;
    }
    if (yMax <= roomSize.yMax && rounded.yMax > roomSize.yMax) {
      rounded.yMax = roomSize.yMax;
    }

    return rounded;
  }

  private isWallMount() {
    return [SensorMounting.Wall, SensorMounting.Tilt45Deg].includes(
      this.getSettings().deviceSettingsForm.sensorMounting
    );
  }
}
