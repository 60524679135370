import { Injectable } from "@angular/core";
import { HomeDevice, DeviceAdminAPI } from "@walabot-mqtt-dashboard/api";

/**
 * Service responsible for querying and searching devices using various criteria.
 * Provides methods to find devices by ID, name, serial number, caregiver details,
 * and creation date.
 */
@Injectable({
  providedIn: "root",
})
export class DeviceQueryService {
  /**
   * Retrieves a paginated list of devices
   * @param fromDeviceId Starting device ID for pagination
   * @param limit Maximum number of devices to return
   */
  public async getDevices(fromDeviceId: string, limit: number) {
    return this.queryDevices({
      fromDeviceId,
      limit,
    });
  }

  /**
   * Finds a specific device by its ID
   * @param deviceId The unique identifier of the device
   */
  public async getDeviceById(deviceId: string): Promise<HomeDevice> {
    const devices = await this.queryDevices({ deviceId });
    return devices[0];
  }

  /**
   * Searches for devices by name
   * @param value The device name to search for
   */
  public async findDeviceByName(value: string): Promise<HomeDevice[]> {
    return this.queryDevices({
      deviceName: value,
    });
  }

  /**
   * Searches for devices by serial number
   * @param serial The serial number to search for
   */
  public async findDeviceBySerial(serial: string): Promise<HomeDevice[]> {
    const remaining = 16 - serial.length;
    let searchVal = serial;
    if (remaining > 0) {
      searchVal = serial.concat("z".repeat(remaining));
    }
    const devices = await this.queryDevices({
      startSerial: serial,
      endSerial: searchVal,
    });
    console.log("found", devices.length, "devices");
    return devices;
  }

  /**
   * Finds devices by ID, supporting both raw MAC address and encoded ID formats
   * @param value Device ID or MAC address
   */
  public async findDeviceById(value: string): Promise<HomeDevice[]> {
    let deviceId = value;
    if (value.match("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$")) {
      deviceId = "id_" + btoa(value).replace("=", "");
      console.log(deviceId);
    }
    return this.queryDevices({ deviceId });
  }

  /**
   * Finds devices associated with a specific caregiver by UID
   * @param uid Caregiver's unique identifier
   */
  public async findDeviceByCareGiverUid(uid: string): Promise<HomeDevice[]> {
    return this.queryDevices({ caregiverUid: uid });
  }

  /**
   * Finds devices associated with a caregiver by name
   * @param name Caregiver's name
   */
  public async findDeviceByCareGiverName(name: string): Promise<HomeDevice[]> {
    return this.queryDevices({ caregiverName: name });
  }

  /**
   * Finds devices associated with a caregiver by phone number
   * @param phoneNumber Caregiver's phone number
   */
  public async findDeviceByCareGiverNumber(
    phoneNumber: string
  ): Promise<HomeDevice[]> {
    return this.queryDevices({ caregiverNumber: phoneNumber });
  }

  /**
   * Finds devices created within a specific date range
   * @param fromDateTime Start date
   * @param toDateTime End date
   */
  public async findDevicesByCreationDate(
    fromDateTime: Date,
    toDateTime: Date
  ): Promise<HomeDevice[]> {
    const startTimestamp = fromDateTime.getTime();
    const endTimestamp = toDateTime.getTime();

    console.log(
      "getting devices created  ",
      fromDateTime.toUTCString(),
      "until",
      toDateTime.toUTCString()
    );
    const devices = await this.queryDevices({
      startCreationTimestamp: startTimestamp,
      endCreationTimestamp: endTimestamp,
      orderBy: "creationTimestamp",
      direction: "asc",
    });
    console.log("found", devices.length, "devices");
    return devices;
  }

  /**
   * Internal method to query devices with various filter criteria
   * @param filter Query parameters for filtering devices
   * @private
   */
  private queryDevices(filter: {
    deviceId?: string;
    deviceName?: string;
    startCreationTimestamp?: number;
    endCreationTimestamp?: number;
    startSerial?: string;
    endSerial?: string;
    caregiverUid?: string;
    caregiverName?: string;
    caregiverNumber?: string;
    fromDeviceId?: string;
    orderBy?: string;
    direction?: "desc" | "asc";
    limit?: number;
  }): Promise<HomeDevice[]> {
    return DeviceAdminAPI.queryDevices(
      filter.deviceId,
      filter.deviceName,
      filter.startCreationTimestamp,
      filter.endCreationTimestamp,
      filter.startSerial,
      filter.endSerial,
      filter.caregiverUid,
      filter.caregiverName,
      filter.caregiverNumber,
      filter.fromDeviceId,
      filter.orderBy,
      filter.direction,
      filter.limit
    );
  }
}
