import { Injectable } from "@angular/core";
import {
  CareGiver,
  CaregiverAPI,
  PartialCareGiver,
  DeviceAdminAPI,
} from "@walabot-mqtt-dashboard/api";

/**
 * Service responsible for managing caregiver-related operations.
 * Handles emergency contacts, caregiver associations, and caregiver updates.
 */
@Injectable({
  providedIn: "root",
})
export class CaregiverService {
  /**
   * Retrieves emergency contacts for a specific owner
   * @param ownerUid The unique identifier of the owner
   */
  public async getEmergencyContacts(ownerUid: string): Promise<CareGiver[]> {
    return DeviceAdminAPI.getCaregiverEmergencyContacts(ownerUid);
  }

  /**
   * Gets the primary paired caregiver for a device
   * @param deviceId The unique identifier of the device
   */
  public async getPairedCareGiver(deviceId: string): Promise<CareGiver> {
    return CaregiverAPI.getPairedCaregiver(deviceId);
  }

  /**
   * Updates caregiver information with admin privileges
   * @param uid Caregiver's unique identifier
   * @param careGiver Partial caregiver information to update
   */
  public adminUpdateCaregiver(uid: string, careGiver: PartialCareGiver) {
    return CaregiverAPI.adminUpdateCaregiver(uid, careGiver);
  }
}
