import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import {
  OwlDateTimeIntl,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { DateTimeInl } from "./date-time-locale/DateTimeInl";
import { IntlTelInputComponent } from "./intl-tel-input/intl-tel-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSliderModule } from "@angular/material/slider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatListModule } from "@angular/material/list";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { MatSortModule } from "@angular/material/sort";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatRadioModule } from "@angular/material/radio";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { OverlayModule } from "@angular/cdk/overlay";
import { PasswordDialogComponent } from "./password-dialog/password-dialog.component";
import { RoomsComponent } from "./rooms/rooms.component";
import { BaseComponent } from "./base-component";
import { AuthService, AppUser } from "./auth.service";
import { AuthGuardService } from "./auth/auth-guard.service";
import { SecondsToDurationPipe } from "./transformations/seconds-to-duration.pipe";
import { FormatPercentagePipe } from "./transformations/format-percentage.pipe";
import { MetersToFeetinchesPipe } from "./transformations/meters-to-feetinches.pipe";
import { LinebreakPipe } from "./transformations/linebreak.pipe";
import { UsersService } from "./users.service";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { DeviceMonitoringService } from "./device-monitoring.service";
import { WebsocketService } from "./websocket.service";
import { RawDataComponent } from "./raw-data/raw-data.component";
import { RoomsService, DeviceRoomConfigMapping } from "./rooms.service";
import { HistoryResult, HistoryService, Query } from "./history.service";
import { EventBusService } from "./event-bus.service";
import { CareGiversService } from "./care-givers.service";
import {
  ConfigService,
  isInLearningMode,
  MeasurementUnits,
} from "./config.service";
import { UpdateNotificationComponent } from "./update-notification/update-notification.component";
import { UpdateService } from "./update.service";
import { getDeviceIcon, RoomTypeMapping } from "./device-icon";
import { HistoryComponent } from "./history/history.component";
import { EXTRA_DEVICE_ID, TargetsComponent } from "./targets/targets.component";
import { StatusComponent } from "./status/status.component";
import { CareGiversComponent } from "./configuration/care-givers/care-givers.component";
import { RoomsSettingsComponent } from "./configuration/rooms-settings/rooms-settings.component";
import { DeviceSettingsComponent } from "./configuration/device-settings/device-settings.component";

import { DashboardSettingsComponent } from "./configuration/dashboard-settings/dashboard-settings.component";
import { RoomSizeComponent } from "./configuration/device-settings/room-size/room-size.component";
import { DateTimezoneNamePipe } from "./transformations/date-timzone-name.pipe";
import { SelectLanguageComponent } from "./select-language/select-language.component";
import { TooltipDirective } from "./directive/tooltip.directive";
import { SuitesService } from "./suites.service";
import { SubRegionsComponent } from "./configuration/device-settings/sub-regions/sub-regions.component";
import { GeneralDetailsComponent } from "./configuration/device-settings/general-details/general-details.component";
import { CustomMatPaginatorIntl } from "./custom-mat-pagination-init/CustomMatPaginatorIntl";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DialogComponent } from "./dialog/dialog.component";
import { UserService } from "./admin/auth/user.service";
import { AdminDashboardModule } from "./admin/dashboard/admin-dashboard.module";
import { DevicesComponent } from "./admin/dashboard/devices/devices.component";
import { DeviceDetailsComponent } from "./admin/dashboard/device-details/device-details.component";
import { AdminAuthGuard } from "./admin/auth/auth-guard.service";
import { VarDirective } from "./ng-var.directive";
import { DurationPipe } from "./transformations/duration.pipe";
import { MatChipsModule } from "@angular/material/chips";
import { AppComponent } from "./app/app.component";
import { AppComponent as AppAdminComponent } from "./app/admin/app.component";
import { RouterModule } from "@angular/router";
import { Preview2dComponent } from "./configuration/device-settings/preview2d/preview2d.component";
import { ZippyContentDirective } from "./ng-zip.directive";
import { HtmlElementDirective } from "./ng-html.directive";
import { Preview3dComponent } from "./configuration/device-settings/preview3d/preview3d.component";
import { VCPlatformAppComponent } from "./vc-platform-app/vc-platform-app.component";
import { SuiteComponent } from "./configuration/suite/suite.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { AlertsHistoryComponent } from "./alerts-history/alerts-history.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { InsightsComponent } from "./insights/insights.component";
import { ChartComponent } from "./chart/chart.component";
import {
  AnalyticsService,
  FacilityAnalytics,
  RiskTrendPiece,
} from "./analytics.service";
import { RiskByApartmentComponent } from "./risk-by-apartment/risk-by-apartment.component";
import { UnresolvedAlertsDialogComponent } from "./alerts-history/unresolved-alerts-dialog/unresolved-alerts-dialog.component";
import { VCPlatformLoginComponent } from "./vc-platform-login/vc-platform-login.component";
import { PotentialFallsComponent } from "./potential-falls/potential-falls.component";
import { NewFallersComponent } from "./new-fallers/new-fallers.component";
import { FallsByShiftPerDayComponent } from "./falls-by-shift-per-day/falls-by-shift-per-day.component";
import { IsLastInFourWeeksPipe } from "./transformations/is-last-in-four-weeks.pipe";
import { FloatPipe } from "./transformations/float.pipe";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSliderModule,
    MatSelectModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatButtonModule,
    MatGridListModule,
    MatListModule,
    MatDialogModule,
    MatCardModule,
    MatMenuModule,
    MatSortModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTooltipModule,
    DragDropModule,
    OverlayModule,
    AdminDashboardModule,
    MatChipsModule,
    RouterModule,
  ],
  exports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSliderModule,
    MatSelectModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatButtonModule,
    MatGridListModule,
    MatListModule,
    MatDialogModule,
    MatCardModule,
    MatMenuModule,
    MatSortModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTooltipModule,
    DragDropModule,
    OverlayModule,
    LoginComponent,
    ForgotPasswordComponent,
    IntlTelInputComponent,
    PasswordDialogComponent,
    UpdateNotificationComponent,
    HistoryComponent,
    TargetsComponent,
    StatusComponent,
    RoomsComponent,
    SecondsToDurationPipe,
    DurationPipe,
    FloatPipe,
    FormatPercentagePipe,
    MetersToFeetinchesPipe,
    LinebreakPipe,
    CareGiversComponent,
    RoomsSettingsComponent,
    DeviceSettingsComponent,
    DashboardSettingsComponent,
    RoomSizeComponent,
    DateTimezoneNamePipe,
    SelectLanguageComponent,
    AppComponent,
    VCPlatformAppComponent,
    AppAdminComponent,
    AlertsComponent,
    AlertsHistoryComponent,
  ],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    IntlTelInputComponent,
    PasswordDialogComponent,
    RawDataComponent,
    UpdateNotificationComponent,
    HistoryComponent,
    TargetsComponent,
    StatusComponent,
    RoomsComponent,
    SecondsToDurationPipe,
    DurationPipe,
    FloatPipe,
    FormatPercentagePipe,
    MetersToFeetinchesPipe,
    LinebreakPipe,
    CareGiversComponent,
    RoomsSettingsComponent,
    DeviceSettingsComponent,
    DashboardSettingsComponent,
    RoomSizeComponent,
    DateTimezoneNamePipe,
    SelectLanguageComponent,
    TooltipDirective,
    SubRegionsComponent,
    GeneralDetailsComponent,
    DialogComponent,
    UnresolvedAlertsDialogComponent,
    VarDirective,
    AppComponent,
    VCPlatformAppComponent,
    AppAdminComponent,
    Preview2dComponent,
    ZippyContentDirective,
    HtmlElementDirective,
    Preview3dComponent,
    SuiteComponent,
    AlertsComponent,
    AlertsHistoryComponent,
    NotFoundComponent,
    InsightsComponent,
    ChartComponent,
    RiskByApartmentComponent,
    VCPlatformLoginComponent,
    PotentialFallsComponent,
    NewFallersComponent,
    FallsByShiftPerDayComponent,
    IsLastInFourWeeksPipe,
  ],
  providers: [
    { provide: OwlDateTimeIntl, useClass: DateTimeInl },
    DatePipe,
    DateTimezoneNamePipe,
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
  ],
})
export class UiModule {}
export {
  DateTimeInl,
  PasswordDialogComponent,
  UpdateNotificationComponent,
  HistoryComponent,
  RawDataComponent,
  TargetsComponent,
  StatusComponent,
  RoomsComponent,
  BaseComponent,
  AuthService,
  AppUser,
  AuthGuardService,
  AdminAuthGuard,
  UsersService,
  DeviceMonitoringService,
  WebsocketService,
  RoomsService,
  SuitesService,
  DeviceRoomConfigMapping,
  HistoryService,
  HistoryResult,
  Query,
  EventBusService,
  CareGiversService,
  ConfigService,
  isInLearningMode,
  MeasurementUnits,
  UpdateService,
  RoomTypeMapping,
  getDeviceIcon,
  LinebreakPipe,
  EXTRA_DEVICE_ID,
  CareGiversComponent,
  RoomsSettingsComponent,
  DashboardSettingsComponent,
  UserService,
  AdminDashboardModule,
  DevicesComponent,
  DeviceDetailsComponent,
  AppComponent,
  VCPlatformAppComponent,
  AppAdminComponent,
  AlertsComponent,
  AlertsHistoryComponent,
  NotFoundComponent,
  InsightsComponent,
  RiskByApartmentComponent,
  AnalyticsService,
  FacilityAnalytics,
  RiskTrendPiece,
};
export const DATE_TIME_FORMAT = "MMM d, y, hh:mm a";
export const MIN_BUZZER_SOUND = 0;
export const MAX_BUZZER_SOUND = 100;
