/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardDataAppEvent } from '../models/DashboardDataAppEvent';
import type { SubregionAggregateStat } from '../models/SubregionAggregateStat';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataAppEventAPI {

    /**
     * Get stranded history
     * @param suiteId Filter results related to suite
     * @param roomId Filter results related to room
     * @param fromTimestamp Filter results 'fromTimestamp' date in timestamp format
     * @param toTimestamp Filter results 'toTimestamp' date in timestamp format
     * @param fromDuration Filter results with longer duration
     * @param limit Limit the number of results, default 500
     * @returns any OK
     * @throws ApiError
     */
    public static getStrandedHistory(
        suiteId?: string,
        roomId?: string,
        fromTimestamp?: string,
        toTimestamp?: string,
        fromDuration?: number,
        limit?: number,
    ): CancelablePromise<{
        events: Array<DashboardDataAppEvent>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dataAppEvent/strandedHistory',
            query: {
                'suiteId': suiteId,
                'roomId': roomId,
                'fromTimestamp': fromTimestamp,
                'toTimestamp': toTimestamp,
                'fromDuration': fromDuration,
                'limit': limit,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Toilet Aggregate Stat
     * @param suiteId
     * @returns SubregionAggregateStat OK
     * @throws ApiError
     */
    public static getToiletAggregateStat(
        suiteId?: string,
    ): CancelablePromise<Array<SubregionAggregateStat>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dataAppEvent/toiletAggregateStat',
            query: {
                'suiteId': suiteId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
