import { Inject, Injectable } from "@angular/core";
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "firebase/compat/auth";
import { BehaviorSubject } from "rxjs";
import { Environment } from "../../models";
@Injectable({
  providedIn: "root",
})
export class UserService {
  currentUser: BehaviorSubject<firebase.User>;
  constructor(
    @Inject("environment") private environment: { [key: string]: Environment }
  ) {
    firebase.initializeApp(this.environment.fbConfig);
    this.currentUser = new BehaviorSubject<firebase.User>(null);
    onAuthStateChanged(getAuth(), (user: firebase.User) => {
      console.log("onAuthStateChanged ");
      this.currentUser.next(user);
    });
  }

  public async getToken() {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      return await firebase.auth().currentUser.getIdToken(false);
    }
    return null;
  }

  public async isDashboardUser(): Promise<boolean> {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return await new Promise<boolean>((resolve) => {
        const sub = firebase.auth().onAuthStateChanged(async (user) => {
          sub();
          if (user) {
            const token = await firebase.auth().currentUser.getIdTokenResult();
            resolve(!!token.claims["admin"]);
          }
          return resolve(false);
        });
      });
    } else {
      try {
        const token = await currentUser.getIdTokenResult();
        return !!token.claims["admin"];
      } catch (err) {
        console.error("failed to retreive Id Token", err);
        return false;
      }
    }
  }
}
