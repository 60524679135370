import { Injectable } from "@angular/core";
import {
  DeviceStatus,
  DeviceStateGen2,
  DeviceAdminAPI,
  HomeDevice,
} from "@walabot-mqtt-dashboard/api";

/**
 * Service responsible for managing device status and state-related operations.
 * Handles device status queries, offline detection, and monitoring.
 */
@Injectable({
  providedIn: "root",
})
export class DeviceStatusService {
  /**
   * Finds devices with a specific status
   * @param deviceStatus The status to filter by
   */
  public async findDeviceIdsByStatus(deviceStatus: DeviceStatus) {
    const devicesState = await DeviceAdminAPI.queryDevicesState(deviceStatus);
    return devicesState.map((state) => {
      return { deviceId: { deviceId: state.deviceId } } as HomeDevice;
    });
  }

  /**
   * Gets the current status of a specific device
   * @param deviceId The unique identifier of the device
   */
  public async getDeviceStatus(deviceId: string): Promise<DeviceStateGen2> {
    return DeviceAdminAPI.getDeviceState(deviceId);
  }
}
