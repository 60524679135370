/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DataAppOutOfBed = {
    type: DataAppOutOfBed.type;
    payload: {
        /**
         * Timestamp of the event in milliseconds since epoch
         */
        timestamp: number;
        /**
         * The event duration
         */
        duration: number;
    };
};

export namespace DataAppOutOfBed {

    export enum type {
        OutOfBed = 2,
    }


}

