import { Injectable } from "@angular/core";
import { DeviceQueryService } from "./device-query.service";
import { CaregiverService } from "./caregiver.service";
import { DeviceStatusService } from "./device-status.service";

/**
 * Facade service that delegates device-related operations to specialized services.
 * This service maintains backward compatibility while providing a cleaner architecture
 * by delegating operations to more focused services:
 * - DeviceQueryService: For device search and query operations
 * - CaregiverService: For caregiver management operations
 * - DeviceStatusService: For device status and monitoring operations
 */
@Injectable({
  providedIn: "root",
})
export class DevicesService {
  constructor(
    private deviceQueryService: DeviceQueryService,
    private caregiverService: CaregiverService,
    private deviceStatusService: DeviceStatusService
  ) {}

  // Delegating to DeviceQueryService
  public getDevices = this.deviceQueryService.getDevices.bind(
    this.deviceQueryService
  );
  public getDeviceById = this.deviceQueryService.getDeviceById.bind(
    this.deviceQueryService
  );
  public findDeviceByName = this.deviceQueryService.findDeviceByName.bind(
    this.deviceQueryService
  );
  public findDeviceBySerial = this.deviceQueryService.findDeviceBySerial.bind(
    this.deviceQueryService
  );
  public findDeviceById = this.deviceQueryService.findDeviceById.bind(
    this.deviceQueryService
  );
  public findDeviceByCareGiverUid =
    this.deviceQueryService.findDeviceByCareGiverUid.bind(
      this.deviceQueryService
    );
  public findDeviceByCareGiverName =
    this.deviceQueryService.findDeviceByCareGiverName.bind(
      this.deviceQueryService
    );
  public findDeviceByCareGiverNumber =
    this.deviceQueryService.findDeviceByCareGiverNumber.bind(
      this.deviceQueryService
    );
  public findDevicesByCreationDate =
    this.deviceQueryService.findDevicesByCreationDate.bind(
      this.deviceQueryService
    );

  // Delegating to CaregiverService
  public getEmergencyContacts = this.caregiverService.getEmergencyContacts.bind(
    this.caregiverService
  );
  public getPairedCareGiver = this.caregiverService.getPairedCareGiver.bind(
    this.caregiverService
  );
  public adminUpdateCaregiver = this.caregiverService.adminUpdateCaregiver.bind(
    this.caregiverService
  );

  // Delegating to DeviceStatusService
  public findDeviceIdsByStatus =
    this.deviceStatusService.findDeviceIdsByStatus.bind(
      this.deviceStatusService
    );
  public getDeviceStatus = this.deviceStatusService.getDeviceStatus.bind(
    this.deviceStatusService
  );
}
