import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RoomType } from "@walabot-mqtt-dashboard/api";
import { FormControl, ValidationErrors, Validators } from "@angular/forms";

export interface DialogData<T = Record<string, never>> {
  title: string;
  fieldTitle?: string;
  list?: Array<T>;
  selectedValue?: T;
  displayNameForList?: string;
  message?: string;
  inputField?: string;
  typeDialog: DialogType;
  okBtnText: string;
  cancelBtnText: string;
  btnHandler?: (
    dialog: MatDialogRef<DialogComponent<T>>,
    confirmed: boolean,
    data?: T | string
  ) => void;
}

export enum DialogType {
  SELECT = "select",
  INPUT = "input",
  MESSAGE = "message",
}

const { required } = Validators as ValidationErrors | null;

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
})
export class DialogComponent<T = Record<string, never>> implements OnInit {
  inProgress: boolean;
  listOptions: Array<T>;
  selectedValue: T;
  optionKey: string;
  nameFormControl = new FormControl<string>("", [required]);
  typeFormControl = new FormControl<RoomType>(RoomType.Bathroom, [required]);

  constructor(
    public dialog: MatDialogRef<DialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData<T>
  ) {
    this.nameFormControl.setValue(this.data.inputField);
    this.selectedValue = this.data.selectedValue;
  }

  ngOnInit(): void {
    console.log("dialogComp");
  }

  onConfirmed() {
    if (this.data.btnHandler) {
      this.inProgress = true;
      this.data.btnHandler(
        this.dialog,
        true,
        this.selectedValue
          ? this.selectedValue
          : String(this.nameFormControl.value)
      );
    } else {
      this.dialog.close({ confirmed: true });
    }
  }

  onCanceled() {
    if (this.data.btnHandler) {
      this.data.btnHandler(this.dialog, false);
    } else {
      this.dialog.close({ confirmed: false });
    }
  }

  checkDisabled() {
    switch (this.data.typeDialog) {
      case DialogType.SELECT:
        return !this.selectedValue || this.inProgress;
      case DialogType.INPUT:
        return this.inProgress || this.nameFormControl.invalid;
      case DialogType.MESSAGE:
        return this.inProgress;
    }
  }
}
